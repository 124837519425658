import { createElement } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { enableMarginAndPadding } from "../styles/spacing";

const TITLE_SIZES = {
  xs: {
    desktop: 18,
    mobile: 16
  },
  sm: {
    desktop: 24,
    mobile: 24
  },
  md: {
    desktop: 40,
    mobile: 24
  },
  lg: {
    mobile: 32,
    desktop: 40
  },
  xl: {
    desktop: 64,
    mobile: 40
  }
};

export const TEXT_STYLES = {
  caption: `
    font-family: "lucida-sans";
    font-size: 13px;
    line-height: 1.4em;
    letter-spacing: 0.2px;
    font-weight: normal;

    a {
      color: black !important;

      &:hover {
        color: black !important;
        opacity: 0.85;
      }
    }
    
    @media (min-width: 768px) {
      font-size: 12px;
    }

    @media (min-width: 1200px) {
      font-size: 12px;
    }
  `,
  label: `
    line-height: 1.35em;
    font-family: "lucida-sans";
    font-weight: normal;
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 6%;

    @media (min-width: 992px) {
      font-size: 0.85rem;
    }
  `
};

const withDynamicTag = Component => {
  const bucket = Object.create(null);

  const DynamicTag = props => {
    const { tag } = props;

    if (typeof tag !== "string") {
      return createElement(Component, props);
    }

    if (bucket[tag] === undefined) {
      bucket[tag] = Component;
    }

    return createElement(bucket[tag], props);
  };

  const name = Component.displayName || Component.constructor.name;

  if (name) {
    DynamicTag.displayName = `DynamicTag(${name})`;
  }

  return DynamicTag;
};

const TitleBase = styled.h1`
    font-family: "noah-heavy";
    font-weight: 400;
    line-height: 1.2em;
    font-size: ${props => TITLE_SIZES[props.size || "md"].mobile}px;
    text-transform: ${props => (props.uppercase ? "uppercase" : "none")};

    @media (min-width: 768px) {
        font-size: ${props => {
            return TITLE_SIZES[props.size || "md"].desktop;
        }}px;
    }

    ${props => enableMarginAndPadding(props)};
`;

export const Title = withDynamicTag(TitleBase);

Title.propTypes = {
  tag: PropTypes.string,
  bold: PropTypes.bool,
  size: PropTypes.string
};

Title.defaultProps = {
  bold: true,
  tag: "h1"
};

export const Text = styled.span`
    line-height: 1.5em;
    font-size: 0.85rem;
    color: inherit;
    font-family: "lucida-sans", sans-serif;
    font-weight: normal;
    display: block;
        /* font-weight: ${props => (props.bold ? "bold" : "normal")}; */
    ${props => enableMarginAndPadding(props)};

    @media (min-width: 768px) {
        font-size: 1rem;
    }

    ${props => {
        return props.textStyle && TEXT_STYLES[props.textStyle];
    }}
    a {
        color: black !important;
        text-decoration: underline;

        &:hover {
            opacity: 0.75;
        }
    }
`;

Text.propTypes = {
  tag: PropTypes.string,
  textStyle: PropTypes.string,
  bold: PropTypes.bool
};

Text.defaultProps = {
  tag: "p"
};
