import React, {
  useEffect,
  forwardRef,
  useRef,
  createRef,
  useContext, useCallback
} from "react";
import { css } from "@emotion/react";
import styled from "styled-components";
import { Link } from "gatsby";
import { gsap } from "gsap/all";
import { debounce, cloneDeep, findIndex } from "lodash";
import { BLACK } from "../styles/colors";
import { IconPng } from "@components/Icon";
import { ResizeContext } from "../providers/ResizeProvider";
import { items, ITEM_WIDTH, getPageData, getPageKey } from "./page-nav-data";

import {
  TransitionGroup,
  Transition as ReactTransition
} from "react-transition-group";

const H4 = styled.h4`
    font-family: "noah-heavy";
    font-size: 18px;
    text-transform: uppercase;
    color: ${BLACK};
    white-space: pre;
`;

const timeout = 1000;
const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0
  },
  entered: {
    transition: `opacity 350ms ease-in-out`,
    opacity: 1
  },
  exiting: {
    transition: `opacity 350ms ease-in-out`,
    opacity: 0
  }
};

const MenuItem = forwardRef((props, ref) => {
  const { title, url, isBack, isCurrent } = props;
  return (
    <div
      css={css`
          pointer-events: none;
          width: ${ITEM_WIDTH}px;
          width: 100vw;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
      `}
    >
      <Link
        to={url}
        ref={ref}
        css={css`
            padding: 0px 10px;
            border-left: 2px solid ${BLACK};
            width: ${ITEM_WIDTH}px;
            pointer-events: all;
            display: block;
            height: 100%;
            background: white;
            transition: 0.1s all;

            img {
                position: absolute;
                left: -16px;
                margin-top: -3px;
                transition: 0.1s opacity;
                opacity: ${isBack ? "1" : "0"};
            }

            &:hover {
                background: #f9f9f9;

                img {
                        //opacity: ${isCurrent && !isBack ? "0" : "1"} !important;
                    //transform: rotate(180deg);
                }
            }
        `}
      >
        <div
          css={css`
              transform-origin: left top;
              position: absolute;
              left: 0;
              top: 0;
              transform: rotate(-90deg) translate3d(-100%, 0, 0);
              padding: 0px 16px;
              height: ${ITEM_WIDTH}px;
              display: flex;
              align-items: center;
          `}
        >
          <IconPng name="arrowUp" size="xxs" />
          <H4>{title}</H4>
        </div>
      </Link>
    </div>
  );
});

const Transition = ({ children, location }) => {
  const itemRefs = useRef([]);
  const maxWidthRef = useRef(null);
  const pageKeyRef = useRef(null);
  const prevKeyRef = useRef(null);
  const { addResizeCallback } = useContext(ResizeContext);

  const {
    pageKey,
    isSingleBlogPost,
    isSingleProject,
    isSingleKarriere
  } = getPageData({
    pathname: location.pathname
  });

  pageKeyRef.current = pageKey;

  if (itemRefs.current.length !== items.length) {
    itemRefs.current = Array(items.length)
      .fill()
      .map((_, i) => itemRefs.current[i] || createRef());
  }

  const createTimeline = useCallback(() => {
    const divs = itemRefs.current.map(itemRef => itemRef.current);
    let from = "start";
    const currentKey = getPageKey({ pathname: location.pathname });
    const currentKeyIndex = findIndex(items, { key: currentKey });
    const prevKeyIndex = findIndex(items, { key: prevKeyRef.current });
    const scrollBarAddition =
      typeof window === "object" ? (window.innerWidth >= 1200 ? 16 : 0) : 0;
    maxWidthRef.current =
      typeof window === "object"
        ? window.innerWidth -
        (items.length - 1) * ITEM_WIDTH -
        scrollBarAddition
        : 0;

    if (prevKeyIndex !== -1) {
      if (prevKeyIndex > currentKeyIndex) {
        from = "end";
      }
    }
    // if current is
    const tl = gsap.timeline({ paused: true }).to(divs, 0.55, {
      // from: "center",
      x: index => {
        const CURRENT = findIndex(items, { key: pageKeyRef.current });
        const HAS_OFFSET = CURRENT < index;
        const INITIAL_POS = ITEM_WIDTH * index;
        const OFFSET = HAS_OFFSET ? maxWidthRef.current - ITEM_WIDTH : 0;
        return INITIAL_POS + OFFSET;
      },
      stagger: {
        amount: 0.45,
        ease: "power3.easeInOut",
        from: from
      },
      ease: "power3.easeInOut",
      onComplete: () => {
        prevKeyRef.current = getPageKey({ pathname: location.pathname });
      }
    });

    return tl;
  }, [location.pathname]);

  const placeItems = useCallback(() => {
    const tl = createTimeline();
    tl.play();
  }, [createTimeline]);


  useEffect(() => {
    const onResize = debounce(placeItems, 100);
    return addResizeCallback(onResize);
  }, [addResizeCallback, placeItems]);

  useEffect(() => {
    placeItems();
  }, [location.pathname, placeItems]);


  return (
    <React.Fragment>
      <nav
        css={css`
            position: fixed;
            top: 64px;
            right: 0;
            bottom: 0px;
            display: flex;
            z-index: 300;
            left: 0;
            pointer-events: none;

            @media (max-width: 991px) {
                display: none;
            }
        `}
      >
        <span
          css={css`
              position: absolute;
              display: block;
              width: 24px;
              height: 100vh;
              right: 0;
              top: 0;
              background: white;
          `}
        />
        {items.map((item, i) => {
          let _item = cloneDeep(item);
          const CURRENT = findIndex(items, { key: pageKeyRef.current });
          if (item.key === "blog" && isSingleBlogPost) {
            _item.title = "ZURÜCK zu Impulsen";
            return (
              <MenuItem
                index={i}
                isBack={true}
                key={item.key}
                ref={itemRefs.current[i]}
                {..._item}
              />
            );
          } else if (item.key === "projekte" && isSingleProject) {
            _item.title = "ZURÜCK zu Projekten";
            return (
              <MenuItem
                index={i}
                isBack={true}
                key={item.key}
                ref={itemRefs.current[i]}
                {..._item}
              />
            );
          } else if (item.key === "karriere" && isSingleKarriere) {
            _item.title = "ZURÜCK zu Karrieren";
            return (
              <MenuItem
                index={i}
                isBack={true}
                key={item.key}
                ref={itemRefs.current[i]}
                {..._item}
              />
            );
          } else {
            return (
              <MenuItem
                key={item.key}
                index={i}
                isCurrent={CURRENT === i}
                ref={itemRefs.current[i]}
                {...item}
              />
            );
          }
        })}
      </nav>
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: timeout,
            exit: timeout
          }}
        >
          {status => {
            return (
              <div
                style={{
                  ...getTransitionStyles[status]
                }}
              >
                <div>{children}</div>
              </div>
            );
          }}
        </ReactTransition>
      </TransitionGroup>
    </React.Fragment>
  );
};

export default Transition;
