import React, { useState, useEffect } from "react";
import { Global, css } from "@emotion/react";
import Header from "../components/MyHeader";
import Footer from "@components/Footer";
import GlobalFonts from "../fonts/fonts";
import LoadProvider from "../providers/LoadProvider";
import ResizeProvider from "../providers/ResizeProvider";
import Transition from "./transition";
import { BLACK } from "../styles/colors";
import { calculatePadding } from "./page-nav-data";
import Loader from "@components/Loader";

//<Loader />\

// const ITEM_WIDTH = 48;
// const pos = { top: 64, left: 2 * ITEM_WIDTH + 24, right: 3 * ITEM_WIDTH + 24 };

const GlobalStyles = () => (
  <Global
    styles={css`
        * {
            box-sizing: border-box;
            margin: 0;
        }

        html,
        body {
            color: ${BLACK};
        }

        a,
        a:visited {
            color: inherit;
            text-decoration: none;
        }

        button {
            padding: 0px;

            &:hover,
            &:focus {
                outline: none;
            }
        }

        a,
        button {
            transition: 0.15s all;
            cursor: pointer;
        }

        img {
            max-width: 100%;
        }
    `}
  />
);

const Layout = ({ children, location }) => {
  const [padding, setPadding] = useState([0, 0]);


  useEffect(() => {
    const _padding = calculatePadding({ pathname: location.pathname });
    setPadding(_padding);
  }, [location.pathname]);

  return (
    <LoadProvider>
      <ResizeProvider>
        <GlobalFonts />
        <GlobalStyles />
        <Loader />
        <Header location={location} />
        <main
          css={css`
              padding-top: 64px;
              padding-bottom: 0px;
              z-index: 0;
              position: relative;

              @media (min-width: 768px) {
                  padding-top: 64px;
                  padding-bottom: 64px;
              }
          `}
        >
          <Transition location={location}>
            <div
              css={css`
                  z-index: 200;
                  position: relative;
                  pointer-events: none;

                  @media (min-width: 992px) {
                      padding-left: ${padding[0]}px;
                      padding-right: ${padding[1]}px;
                  }
              `}
            >
              <div
                css={css`
                    pointer-events: all;
                    overflow: hidden;
                `}
              >
                {children}
              </div>
            </div>
            <div
              css={css`
                  position: fixed;
                  height: 100%;
                  width: 100%;
                  top: 0;
                  left: 0;
                  padding: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  background: white;
                  z-index: 5000;
                  display: none;
                  @media (min-width: 768px) {
                      display: none;
                  }
              `}
            >
              <p>please see on desktop</p>
            </div>
          </Transition>
        </main>
        <Footer />
      </ResizeProvider>
    </LoadProvider>
  );
};

export default Layout;
